document.addEventListener('DOMContentLoaded', () => {
	if (document.querySelector('.mobileNav__list .menu-item-has-children')) {
		let subMenus = document.querySelectorAll(
			'.mobileNav__list .menu-item-has-children'
		);
		subMenus.forEach((subMenu) => {
			let link = subMenu.querySelector('a');
			let span = subMenu.querySelector('a>span');
			let ul = subMenu.querySelector('ul');
			link.addEventListener('click', (e) => {
				if (subMenu.querySelector('span')) {
					e.preventDefault();
					ul.classList.toggle('sub-menu--open');
					span.classList.toggle('open');
				}
			});
		});
	}
	const hamburger = document.querySelector('.header .hamburger');
	const mobileNav = document.querySelector('.mobileNav');
	hamburger.addEventListener('click', () => {
		hamburger.classList.toggle('hamburger--opened');
		hamburger.setAttribute(
			'aria-expanded',
			hamburger.classList.contains('hamburger--opened')
		);
		mobileNav.classList.toggle('open');
	});
	const header = document.querySelector('.header');
	window.addEventListener('scroll', () => {
		document.body.scrollTop > 40 || document.documentElement.scrollTop > 40
			? header.classList.add('header--scrolled')
			: header.classList.remove('header--scrolled');
	});

	const btn = document.querySelector('.scrollTop');

	btn.addEventListener('click', () => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth',
		});
	});

	window.onscroll = function () {
		scrollFunction();
	};

	function scrollFunction() {
		if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
			btn.style.display = 'flex';
		} else {
			btn.style.display = 'none';
		}
	}

	if (document.querySelector('.intro')) {
		const intro = new Swiper('.intro .swiper', {
			slidesPerView: 1,
			spaceBetween: 30,
			// effect: 'fade',
			speed: 1000,
			autoplay: {
				delay: 3000,
			},
			navigation: {
				nextEl: '.introSlider__next',
				prevEl: '.introSlider__prev',
			},
		});
	}

	if (document.querySelector('.about')) {
		const about = new Swiper('.about .swiper', {
			slidesPerView: 1,
			spaceBetween: 30,
			effect: 'fade',
			speed: 1000,
			autoplay: {
				delay: 3000,
			},
			navigation: {
				nextEl: '.aboutSlider__next',
				prevEl: '.aboutSlider__prev',
			},
		});
	}
	if (document.querySelector('.news')) {
		const news = new Swiper('.news .swiper', {
			slidesPerView: 1,
			spaceBetween: 30,
			speed: 1000,
			autoplay: {
				delay: 3000,
			},
			navigation: {
				nextEl: '.newsSlider__next',
				prevEl: '.newsSlider__prev',
			},
			breakpoints: {
				668: {
					slidesPerView: 2,
				},
				1100: {
					slidesPerView: 3,
				},
			},
		});

		const cursor = document.querySelector('.news__slider > svg ');
		const slider = document.querySelector('.news__slider  ');
		const container = document.querySelector('.news .container ');
		slider.addEventListener('mouseover', (e) => {
			cursor.style.display = 'flex';
		});
		slider.addEventListener('mousemove', (e) => {
			var offsetx = (window.innerWidth - slider.clientWidth) / 2;
			var computedSlider = window.getComputedStyle(slider);
			var w = e.clientX - offsetx;
			var h = e.clientY + window.scrollY - container.offsetTop - slider.offsetTop;
			console.log(window.scrollY);
			console.log(container.offsetTop);
			console.log(slider.offsetTop);
			console.log(slider.style.marginTop);
			cursor.style.left = w - 55 + 'px';
			cursor.style.top = h - 55 + 'px';
		});
		slider.addEventListener('mouseleave', (e) => {
			cursor.style.display = 'none';
		});
	}

	if (document.querySelector('.getToKnowUs')) {
		const items = document.querySelectorAll('.getToKnowUs__item');
		const images = document.querySelectorAll('.getToKnowUs__image');
		const cursor = document.querySelector('.getToKnowUs__click');

		items.forEach((item) => {

			let mouseX = 0;
			let mouseY = 0;
	
			let ballX = 0;
			let ballY = 0;
	
			let speed = 0.1;
			var index = parseInt(item.getAttribute('data-index'));
			item.addEventListener('mouseover', (e) => {
				images[index - 1].style.left = e.clientX + 'px';
				images[index - 1].style.top = e.clientY + 'px';

				 ballX = e.clientX;
				 ballY = e.clientY;
				images[index - 1].style.display = 'flex';
				cursor.style.display = 'flex';
			});
			item.addEventListener('mousemove', (e) => {
				cursor.style.left = e.clientX + 'px';
				cursor.style.top = e.clientY + 'px';
				mouseX = e.clientX;
				mouseY = e.clientY;
			});
			item.addEventListener('mouseleave', (e) => {
				cursor.style.display = 'none';
				images[index - 1].style.display = 'none';
			});

		function animate() {
			let distX = mouseX - ballX;
			let distY = mouseY - ballY;

			ballX = ballX + distX * speed;
			ballY = ballY + distY * speed;

			images[index - 1].style.left  = ballX + 'px';
			images[index - 1].style.top  = ballY + 'px';

			requestAnimationFrame(animate);
		}
		animate();
		});

	}

	if (document.querySelector('.ourChefs')) {
		const cards = new Swiper('.ourChefs__cardSlider .swiper', {
			slidesPerView: 1,
			spaceBetween: 30,
			speed: 1000,
			autoplay: {
				delay: 3000,
			},
			breakpoints: {
				668: {
					slidesPerView: 2,
				},
				1100: {
					slidesPerView: 3,
				},
			},
		});
		const names = new Swiper('.ourChefs__nameSlider .swiper', {
			slidesPerView: 1,
			spaceBetween: 30,
			speed: 1000,
			navigation: {
				nextEl: '.nameSlider__next',
				prevEl: '.nameSlider__prev',
			},
			breakpoints: {
				668: {
					slidesPerView: 2,
				},
				1100: {
					slidesPerView: 3,
				},
			},
		});

		cards.controller.control = names;
		names.controller.control = cards;

		const cursor = document.querySelector('.ourChefs__cardSlider svg ');
		const slider = document.querySelector('.ourChefs__cardSlider ');
		const container = document.querySelector('.ourChefs .container ');
		slider.addEventListener('mouseover', (e) => {
			cursor.style.display = 'flex';
		});
		slider.addEventListener('mousemove', (e) => {
			var offsetx = (window.innerWidth - slider.clientWidth) / 2;
			var w = e.clientX - offsetx;
			var h =
				e.clientY + window.scrollY - container.offsetTop - window.innerHeight / 2;
			cursor.style.left = w - 55 + 'px';
			cursor.style.top = h + 'px';
		});
		slider.addEventListener('mouseleave', (e) => {
			cursor.style.display = 'none';
		});
	}
	if (document.querySelector('.gallery')) {
		const gallery = new Swiper('.gallery .swiper', {
			slidesPerView: 1,
			centerMode: 1,
			spaceBetween: 10,
			speed: 1000,
			autoplay: {
				delay: 3000,
			},
			navigation: {
				nextEl: '.gallery__next',
				prevEl: '.gallery__prev',
			},
			breakpoints: {
				668: {
					slidesPerView: 3,
				},
				1100: {
					slidesPerView: 4,
					centerMode: 0,
					spaceBetween: 30,
				},
				// 1100:{
				// 	slidesPerView:3
				// }
			},
		});
	}
});
